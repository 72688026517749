import React from "react";
import {css, useTheme} from "@emotion/react";

import {borderRadius, flexAbsoluteCenter} from "@pg-design/helpers-css";
import {IIcon} from "@pg-design/icons-module";
import {Text} from "@pg-design/text-module";

export const INFO_PANEL_ICON_SIZE = "6";
export const INFO_PANEL_ICON_WRAPPER_SIZE = "6";

interface IProps {
    className?: string;
    icon: React.ComponentType<IIcon>;
    heading: string;
    headingAs?: "h1" | "h2" | "div" | "p";
    subHeading: React.ReactNode;
    subHeadingAs?: "p" | "span";
}

export const InfoPanel = (props: IProps) => {
    const {icon: Icon} = props;

    const theme = useTheme();

    return (
        <div css={[infoPanel, borderRadius(2)]} className={props.className}>
            <Icon css={icon} size={INFO_PANEL_ICON_SIZE} wrapperSize={INFO_PANEL_ICON_WRAPPER_SIZE} wrapperColor={theme.colors.primary} wrapperType="circle" />
            <Text as={props.headingAs} align="center" variant="headline_5" mb="0.8rem">
                {props.heading}
            </Text>
            <Text color={theme.colors.gray[700]} as={props.subHeadingAs} align="center" variant="body_copy_2">
                {props.subHeading}
            </Text>
        </div>
    );
};

const infoPanel = css`
    background-color: #fff;
    position: relative;
    padding: 4.8rem 1.6rem 3.2rem;
    flex-direction: column;
    ${flexAbsoluteCenter};
`;

const icon = css`
    position: absolute;
    top: 0;
    transform: translateY(-50%);
`;
import React from "react";
import {css, useTheme} from "@emotion/react";

import {borderRadius, flexAbsoluteCenter} from "@pg-design/helpers-css";
import {IIcon} from "@pg-design/icons-module";
import {Text} from "@pg-design/text-module";

export const INFO_PANEL_ICON_SIZE = "6";
export const INFO_PANEL_ICON_WRAPPER_SIZE = "6";

interface IProps {
    className?: string;
    icon: React.ComponentType<IIcon>;
    heading: string;
    headingAs?: "h1" | "h2" | "div" | "p";
    subHeading: React.ReactNode;
    subHeadingAs?: "p" | "span";
}

export const InfoPanel = (props: IProps) => {
    const {icon: Icon} = props;

    const theme = useTheme();

    return (
        <div css={[infoPanel, borderRadius(2)]} className={props.className}>
            <Icon css={icon} size={INFO_PANEL_ICON_SIZE} wrapperSize={INFO_PANEL_ICON_WRAPPER_SIZE} wrapperColor={theme.colors.primary} wrapperType="circle" />
            <Text as={props.headingAs} align="center" variant="headline_5" mb="0.8rem">
                {props.heading}
            </Text>
            <Text color={theme.colors.gray[700]} as={props.subHeadingAs} align="center" variant="body_copy_2">
                {props.subHeading}
            </Text>
        </div>
    );
};

const infoPanel = css`
    background-color: #fff;
    position: relative;
    padding: 4.8rem 1.6rem 3.2rem;
    flex-direction: column;
    ${flexAbsoluteCenter};
`;

const icon = css`
    position: absolute;
    top: 0;
    transform: translateY(-50%);
`;
