import React, {useMemo} from "react";
import {useSelector} from "react-redux";
import {useParams} from "react-router";
import {css, Theme} from "@emotion/react";

import {borderRadius, calculateRemSize, ml} from "@pg-design/helpers-css-strings";
import {getThemeVariable} from "@pg-design/styles-strings";
import {getTextVariantStyleFor} from "@pg-design/text";
import {Text} from "@pg-design/text-module";
import {lowerFirst, upperFirst} from "@pg-mono/nodash";

import {IRPStore} from "../../app/rp_reducer";
import {SeoMainHeadline} from "../../atoms/SeoMainHeadline";
import {Country} from "../../region/types/Country";
import {MULTI_REGION_HEADER_LIMIT} from "../constants/multi_region_header_limit";
import {getCountryId, getCountryName} from "../list/helpers/sub_type/get_fetch_offer_list_query";
import {useOfferListingHeaderData} from "../list/hooks/use_offer_listing_header";
import {IOfferListingParams} from "../types/OfferListingParams";

interface IProps {
    isMap?: boolean;
    onClick?: () => void;
}

export const ListMainHeader = (props: IProps) => {
    const {offerListSubType} = useParams<IOfferListingParams>();
    const latestQuery = useSelector((state: IRPStore) => state.offerList.list.latestQuery);

    const country = getCountryId(latestQuery?.country);

    const {searchPrefix, searchPhrase, regionsOverLimitCount, currentPage} = useOfferListingHeaderData(MULTI_REGION_HEADER_LIMIT);
    const pageDefaultTitle = `${searchPrefix.toLocaleLowerCase()} ${searchPhrase} ${currentPage}`.trim();
    const pageAbroadTitle = `${searchPhrase} ${upperFirst(getCountryName(country))} ${currentPage}`.trim();
    const pageTitle = !country || country === Country.POLAND ? pageDefaultTitle : pageAbroadTitle;

    const renderData = useMemo(() => {
        if (props.isMap) {
            const mapH1RedundantText = "Mapa inwestycji - ";
            const h1WithoutBeginningOfTitle = pageTitle.substring(mapH1RedundantText.length);

            return {
                props: {
                    prefixContent: "Szukasz (na mapie)"
                },
                title: lowerFirst(h1WithoutBeginningOfTitle)
            };
        }

        return {
            props: {
                pageString: ` ${currentPage}`
            },
            title: upperFirst(offerListSubType ? searchPhrase : pageTitle)
        };
    }, [props.isMap, pageTitle, offerListSubType, searchPhrase]);

    return (
        <SeoMainHeadline
            {...renderData.props}
            suffixContent={
                <>{regionsOverLimitCount > 0 && <Text as="span" variant="headline_6" css={regionOverLimitTextWrapper}>{`+${regionsOverLimitCount}`}</Text>}</>
            }
            highlight
            onClick={props.onClick}
            css={headerText}
        >
            {renderData.title}
        </SeoMainHeadline>
    );
};

const headerText = (theme: Theme) => css`
    padding-bottom: 1rem;
    ${getTextVariantStyleFor("headline_6")(theme)};

    @media (min-width: ${theme.breakpoints.md}) {
        ${getTextVariantStyleFor("headline_2")(theme)};
    }
`;

const regionOverLimitTextWrapper = css`
    ${ml(1)};
    ${borderRadius(3)};
    display: inline-flex;
    justify-content: center;
    vertical-align: middle;
    align-items: center;
    min-width: ${calculateRemSize(4.5)};
    height: ${calculateRemSize(4.5)};
    background: ${getThemeVariable("colors.primary")};
    cursor: pointer;
`;
import React, {useMemo} from "react";
import {useSelector} from "react-redux";
import {useParams} from "react-router";
import {css, Theme} from "@emotion/react";

import {borderRadius, calculateRemSize, ml} from "@pg-design/helpers-css-strings";
import {getThemeVariable} from "@pg-design/styles-strings";
import {getTextVariantStyleFor} from "@pg-design/text";
import {Text} from "@pg-design/text-module";
import {lowerFirst, upperFirst} from "@pg-mono/nodash";

import {IRPStore} from "../../app/rp_reducer";
import {SeoMainHeadline} from "../../atoms/SeoMainHeadline";
import {Country} from "../../region/types/Country";
import {MULTI_REGION_HEADER_LIMIT} from "../constants/multi_region_header_limit";
import {getCountryId, getCountryName} from "../list/helpers/sub_type/get_fetch_offer_list_query";
import {useOfferListingHeaderData} from "../list/hooks/use_offer_listing_header";
import {IOfferListingParams} from "../types/OfferListingParams";

interface IProps {
    isMap?: boolean;
    onClick?: () => void;
}

export const ListMainHeader = (props: IProps) => {
    const {offerListSubType} = useParams<IOfferListingParams>();
    const latestQuery = useSelector((state: IRPStore) => state.offerList.list.latestQuery);

    const country = getCountryId(latestQuery?.country);

    const {searchPrefix, searchPhrase, regionsOverLimitCount, currentPage} = useOfferListingHeaderData(MULTI_REGION_HEADER_LIMIT);
    const pageDefaultTitle = `${searchPrefix.toLocaleLowerCase()} ${searchPhrase} ${currentPage}`.trim();
    const pageAbroadTitle = `${searchPhrase} ${upperFirst(getCountryName(country))} ${currentPage}`.trim();
    const pageTitle = !country || country === Country.POLAND ? pageDefaultTitle : pageAbroadTitle;

    const renderData = useMemo(() => {
        if (props.isMap) {
            const mapH1RedundantText = "Mapa inwestycji - ";
            const h1WithoutBeginningOfTitle = pageTitle.substring(mapH1RedundantText.length);

            return {
                props: {
                    prefixContent: "Szukasz (na mapie)"
                },
                title: lowerFirst(h1WithoutBeginningOfTitle)
            };
        }

        return {
            props: {
                pageString: ` ${currentPage}`
            },
            title: upperFirst(offerListSubType ? searchPhrase : pageTitle)
        };
    }, [props.isMap, pageTitle, offerListSubType, searchPhrase]);

    return (
        <SeoMainHeadline
            {...renderData.props}
            suffixContent={
                <>{regionsOverLimitCount > 0 && <Text as="span" variant="headline_6" css={regionOverLimitTextWrapper}>{`+${regionsOverLimitCount}`}</Text>}</>
            }
            highlight
            onClick={props.onClick}
            css={headerText}
        >
            {renderData.title}
        </SeoMainHeadline>
    );
};

const headerText = (theme: Theme) => css`
    padding-bottom: 1rem;
    ${getTextVariantStyleFor("headline_6")(theme)};

    @media (min-width: ${theme.breakpoints.md}) {
        ${getTextVariantStyleFor("headline_2")(theme)};
    }
`;

const regionOverLimitTextWrapper = css`
    ${ml(1)};
    ${borderRadius(3)};
    display: inline-flex;
    justify-content: center;
    vertical-align: middle;
    align-items: center;
    min-width: ${calculateRemSize(4.5)};
    height: ${calculateRemSize(4.5)};
    background: ${getThemeVariable("colors.primary")};
    cursor: pointer;
`;
