import {useSelector} from "react-redux";
import {useParams} from "react-router";

import {isEqual, pick} from "@pg-mono/nodash";

import {IRPStore} from "../../../app/rp_reducer";
import {generateMetaRegionName} from "../../../meta_data/utils/generate_meta_region_name";
import {Country} from "../../../region/types/Country";
import {RegionType} from "../../../region/types/RegionType";
import {parseQueryParamToInt} from "../../../utils/latest_query";
import {getSearchFilterPrefix} from "../../helpers/searches/generate_name";
import {offerSearchPhraseBuilder} from "../../helpers/searches/search_phrase";
import {IOfferListingParams} from "../../types/OfferListingParams";
import {OfferType} from "../../types/OfferType";
import {getFloorSubFilterText} from "../helpers/get_floor_sub_filter_text";
import {getCountryId} from "../helpers/sub_type/get_fetch_offer_list_query";

export const useOfferListingHeaderData = (multiRegionLimit?: number) => {
    const {offerListSubType, offerListSubFilter} = useParams<IOfferListingParams>();
    const search = useSelector((state: IRPStore) => state.offerList.search);
    const latestQuery = useSelector((state: IRPStore) => state.offerList.list.latestQuery);
    const regions = useSelector((state: IRPStore) => state.offerList.selectedRegions, isEqual);
    const is_luxury = search && search.is_luxury;
    const is_holiday = latestQuery && latestQuery.is_holiday;
    const price_lower_than_average = latestQuery && latestQuery.price_lower_than_average;
    const type = (search && search.type) || (latestQuery && latestQuery.type);
    const construction_end_date = latestQuery && latestQuery.construction_end_date;
    const isFlatsOrHouses = !!type && type !== OfferType.COMMERCIAL;
    const isCity = !!regions && regions.length === 1 && [RegionType.CITY, RegionType.TOWN].includes(regions[0].type);
    const page = latestQuery && latestQuery.page;
    const typeNormalized = typeof type === "string" ? parseQueryParamToInt(type) : type;
    const sharedOptions = {
        is_luxury: Boolean(is_luxury),
        price_lower_than_average: Boolean(price_lower_than_average),
        construction_end_date: construction_end_date
    };
    const searchPrefix = getSearchFilterPrefix(sharedOptions);

    const isMultiRegionSearch = regions.length > 1;
    const regionsOverLimitCount = multiRegionLimit ? regions.length - multiRegionLimit : 0;
    const regionsLimited = isMultiRegionSearch && multiRegionLimit ? regions.slice(0, multiRegionLimit) : regions;
    const regionName = regions.length === 1 ? generateMetaRegionName(regions[0]) : regionsLimited.map((region) => region.full_name_reverted).join(" - ");

    const searchPhrase = offerSearchPhraseBuilder(
        {
            ...sharedOptions,
            regions: regionsLimited,
            regionName,
            is_holiday: Boolean(is_holiday),
            type: type ? typeNormalized : undefined,
            ...pick(
                {
                    ...latestQuery
                },
                latestQuery.country && getCountryId(latestQuery.country) !== Country.POLAND
                    ? ["sort"]
                    : ["sort", "price_0", "price_1", "area_0", "area_1", "rooms_0", "rooms_1", "country", "instalment"]
            ),
            offerListSubType: typeNormalized === OfferType.HOUSE && offerListSubFilter ? offerListSubFilter : offerListSubType
        },
        {
            rooms: {
                showSingleFilterAsNumber: (type === OfferType.FLAT && latestQuery.rooms_1 !== "1") || type === OfferType.HOUSE
            }
        }
    );

    const currentPage = page ? `- Strona ${page}` : "";

    if (latestQuery.country && getCountryId(latestQuery.country) !== Country.POLAND) {
        const newSearchPhrase = searchPhrase.replace(" na sprzedaż", "");

        return {searchPrefix, searchPhrase: newSearchPhrase, regionsOverLimitCount, currentPage};
    }

    const floorSubFilterText = getFloorSubFilterText(offerListSubFilter);

    if (floorSubFilterText && regionName) {
        const newSearchPhrase = searchPhrase.replace(regionName, `${floorSubFilterText} ${regionName}`).replace(" na sprzedaż", "");
        return {
            searchPrefix: "",
            searchPhrase: newSearchPhrase,
            regionsOverLimitCount,
            currentPage
        };
    }

    if (is_luxury && isCity && isFlatsOrHouses) {
        return {searchPrefix, searchPhrase: searchPhrase.replace(regionName, `${regionName} na sprzedaż`), regionsOverLimitCount, currentPage};
    }

    if (price_lower_than_average && isCity && isFlatsOrHouses) {
        return {searchPrefix: "", searchPhrase: searchPhrase.replace(regionName, `na sprzedaż ${regionName} tanio`), regionsOverLimitCount, currentPage};
    }

    if (construction_end_date === "0" && isCity && isFlatsOrHouses) {
        return {searchPrefix, searchPhrase: searchPhrase.replace(regionName, `do zamieszkania ${regionName}`), regionsOverLimitCount, currentPage};
    }

    if (floorSubFilterText) {
        return {
            searchPrefix: "",
            searchPhrase: `${searchPhrase} ${floorSubFilterText}`,
            regionsOverLimitCount,
            currentPage
        };
    }

    return {searchPrefix, searchPhrase, regionsOverLimitCount, currentPage};
};
import {useSelector} from "react-redux";
import {useParams} from "react-router";

import {isEqual, pick} from "@pg-mono/nodash";

import {IRPStore} from "../../../app/rp_reducer";
import {generateMetaRegionName} from "../../../meta_data/utils/generate_meta_region_name";
import {Country} from "../../../region/types/Country";
import {RegionType} from "../../../region/types/RegionType";
import {parseQueryParamToInt} from "../../../utils/latest_query";
import {getSearchFilterPrefix} from "../../helpers/searches/generate_name";
import {offerSearchPhraseBuilder} from "../../helpers/searches/search_phrase";
import {IOfferListingParams} from "../../types/OfferListingParams";
import {OfferType} from "../../types/OfferType";
import {getFloorSubFilterText} from "../helpers/get_floor_sub_filter_text";
import {getCountryId} from "../helpers/sub_type/get_fetch_offer_list_query";

export const useOfferListingHeaderData = (multiRegionLimit?: number) => {
    const {offerListSubType, offerListSubFilter} = useParams<IOfferListingParams>();
    const search = useSelector((state: IRPStore) => state.offerList.search);
    const latestQuery = useSelector((state: IRPStore) => state.offerList.list.latestQuery);
    const regions = useSelector((state: IRPStore) => state.offerList.selectedRegions, isEqual);
    const is_luxury = search && search.is_luxury;
    const is_holiday = latestQuery && latestQuery.is_holiday;
    const price_lower_than_average = latestQuery && latestQuery.price_lower_than_average;
    const type = (search && search.type) || (latestQuery && latestQuery.type);
    const construction_end_date = latestQuery && latestQuery.construction_end_date;
    const isFlatsOrHouses = !!type && type !== OfferType.COMMERCIAL;
    const isCity = !!regions && regions.length === 1 && [RegionType.CITY, RegionType.TOWN].includes(regions[0].type);
    const page = latestQuery && latestQuery.page;
    const typeNormalized = typeof type === "string" ? parseQueryParamToInt(type) : type;
    const sharedOptions = {
        is_luxury: Boolean(is_luxury),
        price_lower_than_average: Boolean(price_lower_than_average),
        construction_end_date: construction_end_date
    };
    const searchPrefix = getSearchFilterPrefix(sharedOptions);

    const isMultiRegionSearch = regions.length > 1;
    const regionsOverLimitCount = multiRegionLimit ? regions.length - multiRegionLimit : 0;
    const regionsLimited = isMultiRegionSearch && multiRegionLimit ? regions.slice(0, multiRegionLimit) : regions;
    const regionName = regions.length === 1 ? generateMetaRegionName(regions[0]) : regionsLimited.map((region) => region.full_name_reverted).join(" - ");

    const searchPhrase = offerSearchPhraseBuilder(
        {
            ...sharedOptions,
            regions: regionsLimited,
            regionName,
            is_holiday: Boolean(is_holiday),
            type: type ? typeNormalized : undefined,
            ...pick(
                {
                    ...latestQuery
                },
                latestQuery.country && getCountryId(latestQuery.country) !== Country.POLAND
                    ? ["sort"]
                    : ["sort", "price_0", "price_1", "area_0", "area_1", "rooms_0", "rooms_1", "country", "instalment"]
            ),
            offerListSubType: typeNormalized === OfferType.HOUSE && offerListSubFilter ? offerListSubFilter : offerListSubType
        },
        {
            rooms: {
                showSingleFilterAsNumber: (type === OfferType.FLAT && latestQuery.rooms_1 !== "1") || type === OfferType.HOUSE
            }
        }
    );

    const currentPage = page ? `- Strona ${page}` : "";

    if (latestQuery.country && getCountryId(latestQuery.country) !== Country.POLAND) {
        const newSearchPhrase = searchPhrase.replace(" na sprzedaż", "");

        return {searchPrefix, searchPhrase: newSearchPhrase, regionsOverLimitCount, currentPage};
    }

    const floorSubFilterText = getFloorSubFilterText(offerListSubFilter);

    if (floorSubFilterText && regionName) {
        const newSearchPhrase = searchPhrase.replace(regionName, `${floorSubFilterText} ${regionName}`).replace(" na sprzedaż", "");
        return {
            searchPrefix: "",
            searchPhrase: newSearchPhrase,
            regionsOverLimitCount,
            currentPage
        };
    }

    if (is_luxury && isCity && isFlatsOrHouses) {
        return {searchPrefix, searchPhrase: searchPhrase.replace(regionName, `${regionName} na sprzedaż`), regionsOverLimitCount, currentPage};
    }

    if (price_lower_than_average && isCity && isFlatsOrHouses) {
        return {searchPrefix: "", searchPhrase: searchPhrase.replace(regionName, `na sprzedaż ${regionName} tanio`), regionsOverLimitCount, currentPage};
    }

    if (construction_end_date === "0" && isCity && isFlatsOrHouses) {
        return {searchPrefix, searchPhrase: searchPhrase.replace(regionName, `do zamieszkania ${regionName}`), regionsOverLimitCount, currentPage};
    }

    if (floorSubFilterText) {
        return {
            searchPrefix: "",
            searchPhrase: `${searchPhrase} ${floorSubFilterText}`,
            regionsOverLimitCount,
            currentPage
        };
    }

    return {searchPrefix, searchPhrase, regionsOverLimitCount, currentPage};
};
