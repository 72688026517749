import React, {Fragment} from "react";
import {css, Theme, useTheme} from "@emotion/react";
import styled from "@emotion/styled";

import {Checkbox} from "@pg-design/checkbox-module";
import {p} from "@pg-design/helpers-css";
import {ChevronRightIcon} from "@pg-design/icons-module";
import {Text} from "@pg-design/text-module";
import {areaFormat, numberFormat} from "@pg-mono/string-utils";

import {ApplicationSourceSection} from "../application/utils/ApplicationSource";
import {useFavourites} from "../favourite/hooks/use_favourites";
import {hitGtmAddToCompareClick} from "../favourite/tracking/favourite_gtm_events";
import {useResponsiveLinkTarget} from "../hooks/use_responsive_link_target";
import {ISalesOffice} from "../offer/types/ISalesOffice";
import {OfferType} from "../offer/types/OfferType";
import {IPropertyListProperty} from "../property/types/PropertyListProperty";
import {handleFavouritesListCheckHit} from "../tracking/algolytics_hits/favourites_list_hit";
import {gtmEventPropertyListClick} from "../tracking/google_tag_manager/gtm_event_property_list";
import {PropertyListApplicationButton} from "./components/property_list_application_button/PropertyListApplicationButton";
import {PropertyListPlanButton} from "./PropertyListPlanButton";
import {propertiesSortOptions} from "./PropertyListTable";

interface IOwnProps {
    property: IPropertyListProperty;
    offer: {
        address: string;
        configuration: {
            is_autoresponder_enabled: boolean;
            limited_presentation?: boolean;
        };
        geo_point: {
            coordinates: [number, number];
        };
        id: number;
        is_phone_clip_enabled: boolean;
        name: string;
        region: {
            id: number;
            full_name: string;
        };
        sales_offices: ISalesOffice[];
        type: OfferType;
    };
    className?: string;
    link: string;
    sort: string | null;
    vendor: {
        id: number;
        name: string;
        offices?: {
            address: string;
            phone: string;
            mobile: string;
            type: number;
            region: {
                id: number;
                short_name: string;
            };
        }[];
        slug: string;
    };
    isDedicatedForRent: boolean;
    onPlanClick: (e: React.MouseEvent<HTMLElement>, index: number) => void;
    index: number;
    sourceSection?: ApplicationSourceSection;
    showPriceColumn: boolean;
    withoutLink?: boolean;
    hideCell?: {
        propertyPlan?: boolean;
        propertyNumber?: boolean;
        propertyRooms?: boolean;
        propertyArea?: boolean;
        plotArea?: boolean;
        propertyFloor?: boolean;
        propertyPrice?: boolean;
        propertyGotoLink?: boolean;
    };
    handleDivClick?: (value: number) => void;
    textLinkButton?: boolean;
    icon?: React.ReactNode;
    comparisonMode?: boolean;
}

interface IProps extends IOwnProps {}

export const floorOrFloors = (offerType: OfferType, propertyFloors: number, propertyFloor: number) => {
    if (offerType === OfferType.HOUSE && propertyFloors >= 1) {
        return numberFormat(propertyFloors);
    } else if (propertyFloor != null) {
        if (propertyFloor === 0) {
            return "Parter";
        } else {
            return numberFormat(propertyFloor);
        }
    }
    return "-";
};

export const PropertyListTableRow: React.FunctionComponent<IProps> = (props) => {
    const theme = useTheme();
    const responsiveLinkTarget = useResponsiveLinkTarget();
    const {isPropertyInFavourites, addPropertyToFavourites, removePropertyFromFavourites} = useFavourites();

    const isInFavourites = isPropertyInFavourites(props.property.id);
    const isActive = (columnSort: string) => props.sort && (columnSort === props.sort || `-${columnSort}` === props.sort);
    const activeNumberClass = isActive(propertiesSortOptions.NUMBER.value) ? "activeCell" : "";
    const activeRoomClass = isActive(propertiesSortOptions.ROOMS.value) ? "activeCell" : "";
    const activePlotAreaClass = isActive(propertiesSortOptions.PLOT_AREA.value) ? "activeCell" : "";
    const activeAreaClass = isActive(propertiesSortOptions.AREA.value) ? "activeCell" : "";
    const activeFloorClass = isActive(propertiesSortOptions.FLOOR.value) ? "activeCell" : "";
    const activePriceClass = isActive(propertiesSortOptions.PRICE.value) ? "activeCell" : "";
    const onPlanClick = (e: React.MouseEvent<HTMLElement>, index: number): void => {
        gtmEventPropertyListClick("rzut");
        props.onPlanClick(e, index);
    };
    const handleDivClick = () => {
        if (props.handleDivClick) {
            props.handleDivClick(props.index);
        }
        gtmEventPropertyListClick("nieruchomosc");
    };

    const [propertyPlan] = props.property.plan_image_pages;

    const component = (
        <>
            <RowCell data-testid="property-plan" planWrapper showOnMobile hideCell={props.hideCell?.propertyPlan}>
                {props.property.plan && propertyPlan && <PropertyListPlanButton onPlanClick={onPlanClick} index={props.index} />}
            </RowCell>

            <RowCell className={activeNumberClass} data-testid="property-number" hideCell={props.hideCell?.propertyNumber}>
                <Text variant="body_copy_2" align="center">
                    {" "}
                    {props.property.number}
                </Text>
            </RowCell>

            {(props.offer.type === OfferType.HOUSE || props.offer.type === OfferType.FLAT) && (
                <RowCell className={activeRoomClass} data-testid="property-rooms" showOnMobile hideCell={props.hideCell?.propertyRooms}>
                    <Text variant="body_copy_2" align="center">
                        {props.property.rooms}
                    </Text>
                </RowCell>
            )}

            <RowCell className={activeAreaClass} data-testid="property-area" showOnMobile hideCell={props.hideCell?.propertyArea}>
                <Text variant="body_copy_2" align="center">
                    {areaFormat(props.property.area, {precision: 2})}
                </Text>
            </RowCell>

            {props.offer.type === OfferType.HOUSE ? (
                <RowCell className={activePlotAreaClass} data-testid="plot-area" hideCell={props.hideCell?.plotArea}>
                    <Text variant="body_copy_2" align="center">
                        {props.property.plot_area ? (
                            <Fragment>
                                {`${numberFormat(props.property.plot_area, {precision: 2})} m`}
                                <sup>2</sup>
                            </Fragment>
                        ) : (
                            "-"
                        )}
                    </Text>
                </RowCell>
            ) : (
                <RowCell className={activeFloorClass} data-testid="property-floor" hideCell={props.hideCell?.propertyFloor}>
                    <Text variant="body_copy_2" align="center">
                        {floorOrFloors(props.offer.type, props.property.floors, props.property.floor)}
                    </Text>
                </RowCell>
            )}

            {props.comparisonMode && (
                <RowCell showOnMobile>
                    <Checkbox
                        css={checkBoxStyle}
                        name="add-to-comparison"
                        id={`add-to-comparison${props.property.id}`}
                        checked={isInFavourites}
                        onChange={async (_, checked) => {
                            const propertyId = props.property.id;
                            const offerId = props.offer.id;
                            if (checked) {
                                await addPropertyToFavourites(propertyId, offerId);
                                hitGtmAddToCompareClick(true);
                                handleFavouritesListCheckHit(true, offerId);
                            } else {
                                await removePropertyFromFavourites(propertyId);
                                hitGtmAddToCompareClick(false);
                                handleFavouritesListCheckHit(false, offerId, propertyId);
                            }
                        }}
                        labelContent="Dodaj do porównania"
                    />
                </RowCell>
            )}

            {props.showPriceColumn && !props.comparisonMode && (
                <RowCell
                    className={activePriceClass}
                    css={priceCell}
                    textLinkButton={props.textLinkButton}
                    data-testid="property-price"
                    showOnMobile
                    hideCell={props.hideCell?.propertyPrice}
                >
                    <PropertyListApplicationButton
                        property={props.property}
                        vendorId={props.vendor.id}
                        isAutoresponderEnabled={props.offer.configuration.is_autoresponder_enabled}
                        applicationSourceSection={props.sourceSection}
                    />
                </RowCell>
            )}

            <RowCell data-testid="property-goto-link" showOnMobile hideCell={props.hideCell?.propertyGotoLink}>
                <div css={arrowWrapper}>{props.icon ? props.icon : <ChevronRightIcon size="3" fill={theme.colors.gray[700]} />}</div>
            </RowCell>
        </>
    );

    return props.withoutLink ? (
        <div onClick={handleDivClick} css={tableRow}>
            {component}
        </div>
    ) : (
        <a href={props.link} onClick={() => gtmEventPropertyListClick("nieruchomosc")} target={responsiveLinkTarget} css={tableRow}>
            {component}
        </a>
    );
};

const tableRow = (props: Theme) => css`
    display: table-row;
    transition: background 0.2s ease-in-out;
    cursor: pointer;

    &,
    &:visited,
    &:hover,
    &:active,
    &:focus {
        text-decoration: none;
        color: ${props.colors.secondary};
    }

    &:nth-of-type(odd) {
        background: ${props.colors.gray[100]};

        @media (min-width: ${props.breakpoints.sm}) {
            background-color: transparent;
        }
    }

    &:nth-of-type(even) {
        background: ${props.colors.gray[200]};
    }

    &:hover {
        background: ${props.colors.gray[400]};
    }

    .activeCell {
        background: ${props.colors.background[200]};
    }
`;

const arrowWrapper = css`
    transform: scale(50%, 50%);
    width: 100%;
    height: 100%;
    display: flex;
    justify-items: center;
    align-items: center;
`;

interface IRowProps {
    showOnMobile?: boolean;
    planWrapper?: boolean;
    hideCell?: boolean;
    textLinkButton?: boolean;
}

const RowCell = styled.div<IRowProps>`
    vertical-align: middle;
    min-height: 54px;
    height: 54px;
    ${p(1)};

    @media (min-width: ${(props) => props.theme.breakpoints.xs}) {
        min-height: 64px;
    }

    ${(props) => css`
        display: ${props.showOnMobile && !props.hideCell ? "table-cell" : "none"};
        @media (min-width: ${props.theme.breakpoints.sm}) {
            display: ${props.hideCell ? "none" : "table-cell"};
        }
    `}

    ${(props) =>
        props.planWrapper &&
        css`
            padding-left: 2rem;
            @media (min-width: ${props.theme.breakpoints.xs}) {
                padding: 0 0 0 3rem;
            }
        `}
    
    ${(props) =>
        props.textLinkButton &&
        css`
            button {
                font-style: normal;
                font-weight: normal;
                text-transform: lowercase;
                background-color: transparent;
                border: none;
                padding-left: 0;

                &:hover,
                &:active,
                &:focus,
                &:focus:active {
                    background-color: transparent;
                    text-decoration: underline;
                }

                span:first-letter {
                    text-transform: uppercase;
                }
            }
        `}
`;

// height 100% of row to avoid missclick
const checkBoxStyle = css`
    height: 100%;
`;

const priceCell = css`
    text-align: center;
`;
import React, {Fragment} from "react";
import {css, Theme, useTheme} from "@emotion/react";
import styled from "@emotion/styled";

import {Checkbox} from "@pg-design/checkbox-module";
import {p} from "@pg-design/helpers-css";
import {ChevronRightIcon} from "@pg-design/icons-module";
import {Text} from "@pg-design/text-module";
import {areaFormat, numberFormat} from "@pg-mono/string-utils";

import {ApplicationSourceSection} from "../application/utils/ApplicationSource";
import {useFavourites} from "../favourite/hooks/use_favourites";
import {hitGtmAddToCompareClick} from "../favourite/tracking/favourite_gtm_events";
import {useResponsiveLinkTarget} from "../hooks/use_responsive_link_target";
import {ISalesOffice} from "../offer/types/ISalesOffice";
import {OfferType} from "../offer/types/OfferType";
import {IPropertyListProperty} from "../property/types/PropertyListProperty";
import {handleFavouritesListCheckHit} from "../tracking/algolytics_hits/favourites_list_hit";
import {gtmEventPropertyListClick} from "../tracking/google_tag_manager/gtm_event_property_list";
import {PropertyListApplicationButton} from "./components/property_list_application_button/PropertyListApplicationButton";
import {PropertyListPlanButton} from "./PropertyListPlanButton";
import {propertiesSortOptions} from "./PropertyListTable";

interface IOwnProps {
    property: IPropertyListProperty;
    offer: {
        address: string;
        configuration: {
            is_autoresponder_enabled: boolean;
            limited_presentation?: boolean;
        };
        geo_point: {
            coordinates: [number, number];
        };
        id: number;
        is_phone_clip_enabled: boolean;
        name: string;
        region: {
            id: number;
            full_name: string;
        };
        sales_offices: ISalesOffice[];
        type: OfferType;
    };
    className?: string;
    link: string;
    sort: string | null;
    vendor: {
        id: number;
        name: string;
        offices?: {
            address: string;
            phone: string;
            mobile: string;
            type: number;
            region: {
                id: number;
                short_name: string;
            };
        }[];
        slug: string;
    };
    isDedicatedForRent: boolean;
    onPlanClick: (e: React.MouseEvent<HTMLElement>, index: number) => void;
    index: number;
    sourceSection?: ApplicationSourceSection;
    showPriceColumn: boolean;
    withoutLink?: boolean;
    hideCell?: {
        propertyPlan?: boolean;
        propertyNumber?: boolean;
        propertyRooms?: boolean;
        propertyArea?: boolean;
        plotArea?: boolean;
        propertyFloor?: boolean;
        propertyPrice?: boolean;
        propertyGotoLink?: boolean;
    };
    handleDivClick?: (value: number) => void;
    textLinkButton?: boolean;
    icon?: React.ReactNode;
    comparisonMode?: boolean;
}

interface IProps extends IOwnProps {}

export const floorOrFloors = (offerType: OfferType, propertyFloors: number, propertyFloor: number) => {
    if (offerType === OfferType.HOUSE && propertyFloors >= 1) {
        return numberFormat(propertyFloors);
    } else if (propertyFloor != null) {
        if (propertyFloor === 0) {
            return "Parter";
        } else {
            return numberFormat(propertyFloor);
        }
    }
    return "-";
};

export const PropertyListTableRow: React.FunctionComponent<IProps> = (props) => {
    const theme = useTheme();
    const responsiveLinkTarget = useResponsiveLinkTarget();
    const {isPropertyInFavourites, addPropertyToFavourites, removePropertyFromFavourites} = useFavourites();

    const isInFavourites = isPropertyInFavourites(props.property.id);
    const isActive = (columnSort: string) => props.sort && (columnSort === props.sort || `-${columnSort}` === props.sort);
    const activeNumberClass = isActive(propertiesSortOptions.NUMBER.value) ? "activeCell" : "";
    const activeRoomClass = isActive(propertiesSortOptions.ROOMS.value) ? "activeCell" : "";
    const activePlotAreaClass = isActive(propertiesSortOptions.PLOT_AREA.value) ? "activeCell" : "";
    const activeAreaClass = isActive(propertiesSortOptions.AREA.value) ? "activeCell" : "";
    const activeFloorClass = isActive(propertiesSortOptions.FLOOR.value) ? "activeCell" : "";
    const activePriceClass = isActive(propertiesSortOptions.PRICE.value) ? "activeCell" : "";
    const onPlanClick = (e: React.MouseEvent<HTMLElement>, index: number): void => {
        gtmEventPropertyListClick("rzut");
        props.onPlanClick(e, index);
    };
    const handleDivClick = () => {
        if (props.handleDivClick) {
            props.handleDivClick(props.index);
        }
        gtmEventPropertyListClick("nieruchomosc");
    };

    const [propertyPlan] = props.property.plan_image_pages;

    const component = (
        <>
            <RowCell data-testid="property-plan" planWrapper showOnMobile hideCell={props.hideCell?.propertyPlan}>
                {props.property.plan && propertyPlan && <PropertyListPlanButton onPlanClick={onPlanClick} index={props.index} />}
            </RowCell>

            <RowCell className={activeNumberClass} data-testid="property-number" hideCell={props.hideCell?.propertyNumber}>
                <Text variant="body_copy_2" align="center">
                    {" "}
                    {props.property.number}
                </Text>
            </RowCell>

            {(props.offer.type === OfferType.HOUSE || props.offer.type === OfferType.FLAT) && (
                <RowCell className={activeRoomClass} data-testid="property-rooms" showOnMobile hideCell={props.hideCell?.propertyRooms}>
                    <Text variant="body_copy_2" align="center">
                        {props.property.rooms}
                    </Text>
                </RowCell>
            )}

            <RowCell className={activeAreaClass} data-testid="property-area" showOnMobile hideCell={props.hideCell?.propertyArea}>
                <Text variant="body_copy_2" align="center">
                    {areaFormat(props.property.area, {precision: 2})}
                </Text>
            </RowCell>

            {props.offer.type === OfferType.HOUSE ? (
                <RowCell className={activePlotAreaClass} data-testid="plot-area" hideCell={props.hideCell?.plotArea}>
                    <Text variant="body_copy_2" align="center">
                        {props.property.plot_area ? (
                            <Fragment>
                                {`${numberFormat(props.property.plot_area, {precision: 2})} m`}
                                <sup>2</sup>
                            </Fragment>
                        ) : (
                            "-"
                        )}
                    </Text>
                </RowCell>
            ) : (
                <RowCell className={activeFloorClass} data-testid="property-floor" hideCell={props.hideCell?.propertyFloor}>
                    <Text variant="body_copy_2" align="center">
                        {floorOrFloors(props.offer.type, props.property.floors, props.property.floor)}
                    </Text>
                </RowCell>
            )}

            {props.comparisonMode && (
                <RowCell showOnMobile>
                    <Checkbox
                        css={checkBoxStyle}
                        name="add-to-comparison"
                        id={`add-to-comparison${props.property.id}`}
                        checked={isInFavourites}
                        onChange={async (_, checked) => {
                            const propertyId = props.property.id;
                            const offerId = props.offer.id;
                            if (checked) {
                                await addPropertyToFavourites(propertyId, offerId);
                                hitGtmAddToCompareClick(true);
                                handleFavouritesListCheckHit(true, offerId);
                            } else {
                                await removePropertyFromFavourites(propertyId);
                                hitGtmAddToCompareClick(false);
                                handleFavouritesListCheckHit(false, offerId, propertyId);
                            }
                        }}
                        labelContent="Dodaj do porównania"
                    />
                </RowCell>
            )}

            {props.showPriceColumn && !props.comparisonMode && (
                <RowCell
                    className={activePriceClass}
                    css={priceCell}
                    textLinkButton={props.textLinkButton}
                    data-testid="property-price"
                    showOnMobile
                    hideCell={props.hideCell?.propertyPrice}
                >
                    <PropertyListApplicationButton
                        property={props.property}
                        vendorId={props.vendor.id}
                        isAutoresponderEnabled={props.offer.configuration.is_autoresponder_enabled}
                        applicationSourceSection={props.sourceSection}
                    />
                </RowCell>
            )}

            <RowCell data-testid="property-goto-link" showOnMobile hideCell={props.hideCell?.propertyGotoLink}>
                <div css={arrowWrapper}>{props.icon ? props.icon : <ChevronRightIcon size="3" fill={theme.colors.gray[700]} />}</div>
            </RowCell>
        </>
    );

    return props.withoutLink ? (
        <div onClick={handleDivClick} css={tableRow}>
            {component}
        </div>
    ) : (
        <a href={props.link} onClick={() => gtmEventPropertyListClick("nieruchomosc")} target={responsiveLinkTarget} css={tableRow}>
            {component}
        </a>
    );
};

const tableRow = (props: Theme) => css`
    display: table-row;
    transition: background 0.2s ease-in-out;
    cursor: pointer;

    &,
    &:visited,
    &:hover,
    &:active,
    &:focus {
        text-decoration: none;
        color: ${props.colors.secondary};
    }

    &:nth-of-type(odd) {
        background: ${props.colors.gray[100]};

        @media (min-width: ${props.breakpoints.sm}) {
            background-color: transparent;
        }
    }

    &:nth-of-type(even) {
        background: ${props.colors.gray[200]};
    }

    &:hover {
        background: ${props.colors.gray[400]};
    }

    .activeCell {
        background: ${props.colors.background[200]};
    }
`;

const arrowWrapper = css`
    transform: scale(50%, 50%);
    width: 100%;
    height: 100%;
    display: flex;
    justify-items: center;
    align-items: center;
`;

interface IRowProps {
    showOnMobile?: boolean;
    planWrapper?: boolean;
    hideCell?: boolean;
    textLinkButton?: boolean;
}

const RowCell = styled.div<IRowProps>`
    vertical-align: middle;
    min-height: 54px;
    height: 54px;
    ${p(1)};

    @media (min-width: ${(props) => props.theme.breakpoints.xs}) {
        min-height: 64px;
    }

    ${(props) => css`
        display: ${props.showOnMobile && !props.hideCell ? "table-cell" : "none"};
        @media (min-width: ${props.theme.breakpoints.sm}) {
            display: ${props.hideCell ? "none" : "table-cell"};
        }
    `}

    ${(props) =>
        props.planWrapper &&
        css`
            padding-left: 2rem;
            @media (min-width: ${props.theme.breakpoints.xs}) {
                padding: 0 0 0 3rem;
            }
        `}
    
    ${(props) =>
        props.textLinkButton &&
        css`
            button {
                font-style: normal;
                font-weight: normal;
                text-transform: lowercase;
                background-color: transparent;
                border: none;
                padding-left: 0;

                &:hover,
                &:active,
                &:focus,
                &:focus:active {
                    background-color: transparent;
                    text-decoration: underline;
                }

                span:first-letter {
                    text-transform: uppercase;
                }
            }
        `}
`;

// height 100% of row to avoid missclick
const checkBoxStyle = css`
    height: 100%;
`;

const priceCell = css`
    text-align: center;
`;
