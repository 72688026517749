import {Country} from "../../../region/types/Country";
import {getCountryName} from "../../../region/utils/get_country_name";
import {GTMEcommerceItem, GTMEcommerceOfferType} from "../../../tracking/google_tag_manager/common_interfaces/GTMEcommerceItem";
import {hitGoogleTagManager} from "../../../tracking/google_tag_manager/init_google_tag_manager";
import {OfferType} from "../../types/OfferType";

export interface IHitOfferListViewsParams {
    offers: {
        id: number;
        name: string;
        type: OfferType;
        region: {
            voivodeship: string | null;
            city: string | null;
            town: string | null;
            district: string | null;
            country: Country;
        };

        vendor: {
            name: string;
        };
    }[];
    listingName: string;
}

export const hitEcommerceOfferListView = (params: IHitOfferListViewsParams) => {
    const items: GTMEcommerceItem[] = params.offers.map((offer, index) => ({
        item_id: offer.id.toString(),
        item_name: offer.name,
        price: 1,
        item_brand: offer.vendor.name,
        item_category: getCountryName(offer.region.country, "pl") || "na",
        item_category2: offer.region.voivodeship || "na",
        item_category3: offer.region.city || offer.region.town || "na",
        item_category4: offer.region.district || "na",
        item_category5: gtmEcommerceOfferTypeMap[offer.type],
        item_list_name: params.listingName,
        index,
        variant: "na"
    }));

    hitGoogleTagManager({
        event: "view_item_list",
        item_list_name: params.listingName,
        ecommerce: {
            items
        }
    });
};

export const gtmEcommerceOfferTypeMap: Record<OfferType, GTMEcommerceOfferType> = {
    [OfferType.HOUSE]: "Domy",
    [OfferType.FLAT]: "Mieszkania",
    [OfferType.COMMERCIAL]: "Lokale użytkowe",
    [OfferType.MODULAR_HOUSE]: "Domy modułowe"
};
import {Country} from "../../../region/types/Country";
import {getCountryName} from "../../../region/utils/get_country_name";
import {GTMEcommerceItem, GTMEcommerceOfferType} from "../../../tracking/google_tag_manager/common_interfaces/GTMEcommerceItem";
import {hitGoogleTagManager} from "../../../tracking/google_tag_manager/init_google_tag_manager";
import {OfferType} from "../../types/OfferType";

export interface IHitOfferListViewsParams {
    offers: {
        id: number;
        name: string;
        type: OfferType;
        region: {
            voivodeship: string | null;
            city: string | null;
            town: string | null;
            district: string | null;
            country: Country;
        };

        vendor: {
            name: string;
        };
    }[];
    listingName: string;
}

export const hitEcommerceOfferListView = (params: IHitOfferListViewsParams) => {
    const items: GTMEcommerceItem[] = params.offers.map((offer, index) => ({
        item_id: offer.id.toString(),
        item_name: offer.name,
        price: 1,
        item_brand: offer.vendor.name,
        item_category: getCountryName(offer.region.country, "pl") || "na",
        item_category2: offer.region.voivodeship || "na",
        item_category3: offer.region.city || offer.region.town || "na",
        item_category4: offer.region.district || "na",
        item_category5: gtmEcommerceOfferTypeMap[offer.type],
        item_list_name: params.listingName,
        index,
        variant: "na"
    }));

    hitGoogleTagManager({
        event: "view_item_list",
        item_list_name: params.listingName,
        ecommerce: {
            items
        }
    });
};

export const gtmEcommerceOfferTypeMap: Record<OfferType, GTMEcommerceOfferType> = {
    [OfferType.HOUSE]: "Domy",
    [OfferType.FLAT]: "Mieszkania",
    [OfferType.COMMERCIAL]: "Lokale użytkowe",
    [OfferType.MODULAR_HOUSE]: "Domy modułowe"
};
