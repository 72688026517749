export enum ArticleCategory {
    Assistance = 1,
    NewsOutlet = 2,
    Taxes = 4,
    Finance = 5,
    Design = 7,
    News = 10,
    Warsaw = 13,
    Cracow = 14,
    Poznan = 15,
    Wroclaw = 16,
    Trojmiasto = 17,
    Szczecin = 18,
    Lodz = 19,
    Katowice = 20,
    Guides = 21,
    AudioVideo = 22,
    TinyFlat = 23,
    InteriorDesign = 24,
    Trends = 25,
    Press = 27,
    ForPress = 28,
    UsInMedia = 29,
    CurrentNews = 30,
    Questions = 31,
    Blog = 32,
    Local = 33,
    Estate = 34,
    Renting = 36,
    Loans = 37,
    Vendors = 40,
    Investments = 41,
    Renovation = 42,
    Architecture = 44,
    LifeStyle = 45,
    Reports = 46,
    AfterPurchase = 47,
    DuringSearch = 48,
    Interviews = 82
}

export const allArticleCategoryList = [
    ArticleCategory.NewsOutlet,
    ArticleCategory.News,
    ArticleCategory.Assistance,
    ArticleCategory.AudioVideo,
    ArticleCategory.Blog,
    ArticleCategory.Cracow,
    ArticleCategory.CurrentNews,
    ArticleCategory.Design,
    ArticleCategory.Estate,
    ArticleCategory.Finance,
    ArticleCategory.ForPress,
    ArticleCategory.Guides,
    ArticleCategory.Katowice,
    ArticleCategory.Local,
    ArticleCategory.Lodz,
    ArticleCategory.Poznan,
    ArticleCategory.Press,
    ArticleCategory.Questions,
    ArticleCategory.InteriorDesign,
    ArticleCategory.Szczecin,
    ArticleCategory.Taxes,
    ArticleCategory.Szczecin,
    ArticleCategory.TinyFlat,
    ArticleCategory.Trends,
    ArticleCategory.Trojmiasto,
    ArticleCategory.UsInMedia,
    ArticleCategory.Warsaw,
    ArticleCategory.Wroclaw,
    ArticleCategory.Renting,
    ArticleCategory.Loans,
    ArticleCategory.Vendors,
    ArticleCategory.Investments,
    ArticleCategory.Renovation,
    ArticleCategory.Architecture,
    ArticleCategory.LifeStyle,
    ArticleCategory.Reports,
    ArticleCategory.DuringSearch,
    ArticleCategory.AfterPurchase,
    ArticleCategory.Interviews
];

export const navigationArticleCategoryList = [
    ArticleCategory.Estate,
    ArticleCategory.Local,
    ArticleCategory.Taxes,
    ArticleCategory.Design,
    ArticleCategory.Finance,
    ArticleCategory.Guides,
    ArticleCategory.Blog
];

/**
 * Helpers
 */

export function getArticleCategoryBySlug(slug: string): ArticleCategory | null {
    return allArticleCategoryList.find((c) => getSlugByArticleCategory(c) === slug) || null;
}

export function getSlugByArticleCategory(category: ArticleCategory) {
    switch (category) {
        case ArticleCategory.Assistance:
            return "pomoc";
        case ArticleCategory.NewsOutlet:
            return "kawiarnia-prasowa";
        case ArticleCategory.News:
            return "wiadomosci";
        case ArticleCategory.Warsaw:
            return "warszawa";
        case ArticleCategory.Cracow:
            return "krakow";
        case ArticleCategory.Poznan:
            return "poznan";
        case ArticleCategory.Wroclaw:
            return "wroclaw";
        case ArticleCategory.Trojmiasto:
            return "trojmiasto";
        case ArticleCategory.Szczecin:
            return "szczecin";
        case ArticleCategory.Lodz:
            return "lodz";
        case ArticleCategory.Katowice:
            return "katowice";
        case ArticleCategory.AudioVideo:
            return "audycje-i-wideo";
        case ArticleCategory.TinyFlat:
            return "male-mieszkanie";
        case ArticleCategory.InteriorDesign:
            return "style";
        case ArticleCategory.Trends:
            return "trendy";
        case ArticleCategory.ForPress:
            return "kontakt-dla-mediow";
        case ArticleCategory.Questions:
            return "pytania";
        case ArticleCategory.UsInMedia:
            return "my-w-mediach";
        case ArticleCategory.CurrentNews:
            return "aktualnosci";
        case ArticleCategory.Estate:
            return "nieruchomosci";
        case ArticleCategory.Local:
            return "regionalne";
        case ArticleCategory.Taxes:
            return "prawo-i-podatki";
        case ArticleCategory.Design:
            return "wyposazenie-wnetrz";
        case ArticleCategory.Finance:
            return "finanse";
        case ArticleCategory.Guides:
            return "porady";
        case ArticleCategory.Blog:
            return "blog";
        case ArticleCategory.Press:
            return "biuro-prasowe";
        case ArticleCategory.Loans:
            return "kredyty";
        case ArticleCategory.Renting:
            return "wynajem-mieszkan";
        case ArticleCategory.Vendors:
            return "firmy-deweloperskie";
        case ArticleCategory.Investments:
            return "inwestowanie-w-nieruchomosci";
        case ArticleCategory.Renovation:
            return "remont";
        case ArticleCategory.Architecture:
            return "architektura";
        case ArticleCategory.LifeStyle:
            return "styl-zycia";
        case ArticleCategory.Reports:
            return "raporty";
        case ArticleCategory.DuringSearch:
            return "szukam-mieszkania";
        case ArticleCategory.AfterPurchase:
            return "mam-mieszkanie";
        case ArticleCategory.Interviews:
            return "wywiady-z-ekspertami";
        default:
            throw new Error(`getSlugByArticleCategory: invalid category ${category}`);
    }
}

export function getNameByArticleCategory(category: ArticleCategory) {
    switch (category) {
        case ArticleCategory.Estate:
            return "Nieruchomości";
        case ArticleCategory.Local:
            return "Regionalne";
        case ArticleCategory.Taxes:
            return "Prawo i podatki";
        case ArticleCategory.Design:
            return "Wyposażenie wnętrz";
        case ArticleCategory.Finance:
            return "Finanse i kredyty";
        case ArticleCategory.Guides:
            return "Porady";
        case ArticleCategory.Blog:
            return "Blog";
        case ArticleCategory.Press:
            return "Biuro prasowe";
        case ArticleCategory.Assistance:
            return "Pomoc";
        case ArticleCategory.Questions:
            return "Pytania";
        case ArticleCategory.ForPress:
            return "Kontakt dla Mediów";
        case ArticleCategory.UsInMedia:
            return "My w mediach";
        case ArticleCategory.Katowice:
            return "Katowice";
        case ArticleCategory.Lodz:
            return "Łódź";
        case ArticleCategory.Szczecin:
            return "Szczecin";
        case ArticleCategory.TinyFlat:
            return "Małe Mieszkanie";
        case ArticleCategory.CurrentNews:
            return "Aktualności";
        case ArticleCategory.Warsaw:
            return "Warszawa";
        case ArticleCategory.Wroclaw:
            return "Wrocław";
        case ArticleCategory.Trojmiasto:
            return "Trójmiasto";
        case ArticleCategory.Poznan:
            return "Poznań";
        case ArticleCategory.Cracow:
            return "Kraków";
        case ArticleCategory.NewsOutlet:
            return "Kawiarnia Prasowa";
        case ArticleCategory.InteriorDesign:
            return "Style wnętrz";
        case ArticleCategory.Trends:
            return "Trendy";
        case ArticleCategory.AudioVideo:
            return "Audycje i Wideo";
        case ArticleCategory.News:
            return "Wiadomości";
        case ArticleCategory.Loans:
            return "Kredyty";
        case ArticleCategory.Renting:
            return "Wynajem Mieszkań";
        case ArticleCategory.Vendors:
            return "Firmy Deweloperskie";
        case ArticleCategory.Investments:
            return "Inwestowanie w nieruchomości";
        case ArticleCategory.Renovation:
            return "Remonty i wykończenia";
        case ArticleCategory.Architecture:
            return "Architektura";
        case ArticleCategory.LifeStyle:
            return "Styl życia";
        case ArticleCategory.Reports:
            return "Raporty i dane rynkowe";
        case ArticleCategory.DuringSearch:
            return "W trakcie poszukiwań";
        case ArticleCategory.AfterPurchase:
            return "Po zakupie mieszkania";
        case ArticleCategory.Interviews:
            return "Wywiady z ekspertami";
        default:
            throw new Error(`getNameByArticleCategory: invalid category ${category}`);
    }
}
export enum ArticleCategory {
    Assistance = 1,
    NewsOutlet = 2,
    Taxes = 4,
    Finance = 5,
    Design = 7,
    News = 10,
    Warsaw = 13,
    Cracow = 14,
    Poznan = 15,
    Wroclaw = 16,
    Trojmiasto = 17,
    Szczecin = 18,
    Lodz = 19,
    Katowice = 20,
    Guides = 21,
    AudioVideo = 22,
    TinyFlat = 23,
    InteriorDesign = 24,
    Trends = 25,
    Press = 27,
    ForPress = 28,
    UsInMedia = 29,
    CurrentNews = 30,
    Questions = 31,
    Blog = 32,
    Local = 33,
    Estate = 34,
    Renting = 36,
    Loans = 37,
    Vendors = 40,
    Investments = 41,
    Renovation = 42,
    Architecture = 44,
    LifeStyle = 45,
    Reports = 46,
    AfterPurchase = 47,
    DuringSearch = 48,
    Interviews = 82
}

export const allArticleCategoryList = [
    ArticleCategory.NewsOutlet,
    ArticleCategory.News,
    ArticleCategory.Assistance,
    ArticleCategory.AudioVideo,
    ArticleCategory.Blog,
    ArticleCategory.Cracow,
    ArticleCategory.CurrentNews,
    ArticleCategory.Design,
    ArticleCategory.Estate,
    ArticleCategory.Finance,
    ArticleCategory.ForPress,
    ArticleCategory.Guides,
    ArticleCategory.Katowice,
    ArticleCategory.Local,
    ArticleCategory.Lodz,
    ArticleCategory.Poznan,
    ArticleCategory.Press,
    ArticleCategory.Questions,
    ArticleCategory.InteriorDesign,
    ArticleCategory.Szczecin,
    ArticleCategory.Taxes,
    ArticleCategory.Szczecin,
    ArticleCategory.TinyFlat,
    ArticleCategory.Trends,
    ArticleCategory.Trojmiasto,
    ArticleCategory.UsInMedia,
    ArticleCategory.Warsaw,
    ArticleCategory.Wroclaw,
    ArticleCategory.Renting,
    ArticleCategory.Loans,
    ArticleCategory.Vendors,
    ArticleCategory.Investments,
    ArticleCategory.Renovation,
    ArticleCategory.Architecture,
    ArticleCategory.LifeStyle,
    ArticleCategory.Reports,
    ArticleCategory.DuringSearch,
    ArticleCategory.AfterPurchase,
    ArticleCategory.Interviews
];

export const navigationArticleCategoryList = [
    ArticleCategory.Estate,
    ArticleCategory.Local,
    ArticleCategory.Taxes,
    ArticleCategory.Design,
    ArticleCategory.Finance,
    ArticleCategory.Guides,
    ArticleCategory.Blog
];

/**
 * Helpers
 */

export function getArticleCategoryBySlug(slug: string): ArticleCategory | null {
    return allArticleCategoryList.find((c) => getSlugByArticleCategory(c) === slug) || null;
}

export function getSlugByArticleCategory(category: ArticleCategory) {
    switch (category) {
        case ArticleCategory.Assistance:
            return "pomoc";
        case ArticleCategory.NewsOutlet:
            return "kawiarnia-prasowa";
        case ArticleCategory.News:
            return "wiadomosci";
        case ArticleCategory.Warsaw:
            return "warszawa";
        case ArticleCategory.Cracow:
            return "krakow";
        case ArticleCategory.Poznan:
            return "poznan";
        case ArticleCategory.Wroclaw:
            return "wroclaw";
        case ArticleCategory.Trojmiasto:
            return "trojmiasto";
        case ArticleCategory.Szczecin:
            return "szczecin";
        case ArticleCategory.Lodz:
            return "lodz";
        case ArticleCategory.Katowice:
            return "katowice";
        case ArticleCategory.AudioVideo:
            return "audycje-i-wideo";
        case ArticleCategory.TinyFlat:
            return "male-mieszkanie";
        case ArticleCategory.InteriorDesign:
            return "style";
        case ArticleCategory.Trends:
            return "trendy";
        case ArticleCategory.ForPress:
            return "kontakt-dla-mediow";
        case ArticleCategory.Questions:
            return "pytania";
        case ArticleCategory.UsInMedia:
            return "my-w-mediach";
        case ArticleCategory.CurrentNews:
            return "aktualnosci";
        case ArticleCategory.Estate:
            return "nieruchomosci";
        case ArticleCategory.Local:
            return "regionalne";
        case ArticleCategory.Taxes:
            return "prawo-i-podatki";
        case ArticleCategory.Design:
            return "wyposazenie-wnetrz";
        case ArticleCategory.Finance:
            return "finanse";
        case ArticleCategory.Guides:
            return "porady";
        case ArticleCategory.Blog:
            return "blog";
        case ArticleCategory.Press:
            return "biuro-prasowe";
        case ArticleCategory.Loans:
            return "kredyty";
        case ArticleCategory.Renting:
            return "wynajem-mieszkan";
        case ArticleCategory.Vendors:
            return "firmy-deweloperskie";
        case ArticleCategory.Investments:
            return "inwestowanie-w-nieruchomosci";
        case ArticleCategory.Renovation:
            return "remont";
        case ArticleCategory.Architecture:
            return "architektura";
        case ArticleCategory.LifeStyle:
            return "styl-zycia";
        case ArticleCategory.Reports:
            return "raporty";
        case ArticleCategory.DuringSearch:
            return "szukam-mieszkania";
        case ArticleCategory.AfterPurchase:
            return "mam-mieszkanie";
        case ArticleCategory.Interviews:
            return "wywiady-z-ekspertami";
        default:
            throw new Error(`getSlugByArticleCategory: invalid category ${category}`);
    }
}

export function getNameByArticleCategory(category: ArticleCategory) {
    switch (category) {
        case ArticleCategory.Estate:
            return "Nieruchomości";
        case ArticleCategory.Local:
            return "Regionalne";
        case ArticleCategory.Taxes:
            return "Prawo i podatki";
        case ArticleCategory.Design:
            return "Wyposażenie wnętrz";
        case ArticleCategory.Finance:
            return "Finanse i kredyty";
        case ArticleCategory.Guides:
            return "Porady";
        case ArticleCategory.Blog:
            return "Blog";
        case ArticleCategory.Press:
            return "Biuro prasowe";
        case ArticleCategory.Assistance:
            return "Pomoc";
        case ArticleCategory.Questions:
            return "Pytania";
        case ArticleCategory.ForPress:
            return "Kontakt dla Mediów";
        case ArticleCategory.UsInMedia:
            return "My w mediach";
        case ArticleCategory.Katowice:
            return "Katowice";
        case ArticleCategory.Lodz:
            return "Łódź";
        case ArticleCategory.Szczecin:
            return "Szczecin";
        case ArticleCategory.TinyFlat:
            return "Małe Mieszkanie";
        case ArticleCategory.CurrentNews:
            return "Aktualności";
        case ArticleCategory.Warsaw:
            return "Warszawa";
        case ArticleCategory.Wroclaw:
            return "Wrocław";
        case ArticleCategory.Trojmiasto:
            return "Trójmiasto";
        case ArticleCategory.Poznan:
            return "Poznań";
        case ArticleCategory.Cracow:
            return "Kraków";
        case ArticleCategory.NewsOutlet:
            return "Kawiarnia Prasowa";
        case ArticleCategory.InteriorDesign:
            return "Style wnętrz";
        case ArticleCategory.Trends:
            return "Trendy";
        case ArticleCategory.AudioVideo:
            return "Audycje i Wideo";
        case ArticleCategory.News:
            return "Wiadomości";
        case ArticleCategory.Loans:
            return "Kredyty";
        case ArticleCategory.Renting:
            return "Wynajem Mieszkań";
        case ArticleCategory.Vendors:
            return "Firmy Deweloperskie";
        case ArticleCategory.Investments:
            return "Inwestowanie w nieruchomości";
        case ArticleCategory.Renovation:
            return "Remonty i wykończenia";
        case ArticleCategory.Architecture:
            return "Architektura";
        case ArticleCategory.LifeStyle:
            return "Styl życia";
        case ArticleCategory.Reports:
            return "Raporty i dane rynkowe";
        case ArticleCategory.DuringSearch:
            return "W trakcie poszukiwań";
        case ArticleCategory.AfterPurchase:
            return "Po zakupie mieszkania";
        case ArticleCategory.Interviews:
            return "Wywiady z ekspertami";
        default:
            throw new Error(`getNameByArticleCategory: invalid category ${category}`);
    }
}
