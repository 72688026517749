import {useId} from "react";

import {useElementWasVisible} from "@pg-mono/hooks";

import {hitEcommerceOfferListView, IHitOfferListViewsParams} from "../../offer/list/tracking/gtm_offer_list_tracking";
import {hitEcommercePropertiesListView} from "../../offer/list/tracking/gtm_property_list_tracking";
import {OfferType} from "../../offer/types/OfferType";
import {IPropertyListProperty} from "../../property/types/PropertyListProperty";
import {Country} from "../../region/types/Country";

interface OffersTrackingParams {
    offers: IHitOfferListViewsParams["offers"];
    listingName: string;
    country: Country;
}

/**
 * function sends GTM event when offer list is visible. Should be used only in listings such as "nearby", "sliders" etc, not on full search results
 * Pass the returned containerId on html element that should be watched to trigger the event
 */
export const useGTMEcommerceShortOfferListTracking = (params: OffersTrackingParams) => {
    const containerId = useId();

    useElementWasVisible({
        ids: [containerId],
        onElementVisible: () => {
            if (params.country === Country.SPAIN) {
                hitEcommerceOfferListView({
                    offers: params.offers,
                    listingName: params.listingName || "na"
                });
            }
        },
        once: true,
        threshold: 0.05
    });

    return containerId;
};

interface IPropertiesTrackingParams {
    offer: {
        id: number;
        name: string;
        type: OfferType;
        region: {
            country: Country;
        };
        vendor: {
            name: string;
        };
    };
    properties: IPropertyListProperty[];
    region: {
        voivodeship: string;
        city: string | null;
        town: string | null;
        district: string | null;
    };
    listingName: string;
    country: Country;
}

export const useGTMEcommercePropertyListTracking = (params: IPropertiesTrackingParams) => {
    const containerId = useId();

    useElementWasVisible({
        ids: [containerId],
        onElementVisible: () => {
            if (params.country === Country.SPAIN) {
                hitEcommercePropertiesListView({
                    offer: params.offer,
                    properties: params.properties,
                    region: params.region,
                    listingName: params.listingName || "na"
                });
            }
        },
        once: true,
        threshold: 0.05
    });

    return containerId;
};
import {useId} from "react";

import {useElementWasVisible} from "@pg-mono/hooks";

import {hitEcommerceOfferListView, IHitOfferListViewsParams} from "../../offer/list/tracking/gtm_offer_list_tracking";
import {hitEcommercePropertiesListView} from "../../offer/list/tracking/gtm_property_list_tracking";
import {OfferType} from "../../offer/types/OfferType";
import {IPropertyListProperty} from "../../property/types/PropertyListProperty";
import {Country} from "../../region/types/Country";

interface OffersTrackingParams {
    offers: IHitOfferListViewsParams["offers"];
    listingName: string;
    country: Country;
}

/**
 * function sends GTM event when offer list is visible. Should be used only in listings such as "nearby", "sliders" etc, not on full search results
 * Pass the returned containerId on html element that should be watched to trigger the event
 */
export const useGTMEcommerceShortOfferListTracking = (params: OffersTrackingParams) => {
    const containerId = useId();

    useElementWasVisible({
        ids: [containerId],
        onElementVisible: () => {
            if (params.country === Country.SPAIN) {
                hitEcommerceOfferListView({
                    offers: params.offers,
                    listingName: params.listingName || "na"
                });
            }
        },
        once: true,
        threshold: 0.05
    });

    return containerId;
};

interface IPropertiesTrackingParams {
    offer: {
        id: number;
        name: string;
        type: OfferType;
        region: {
            country: Country;
        };
        vendor: {
            name: string;
        };
    };
    properties: IPropertyListProperty[];
    region: {
        voivodeship: string;
        city: string | null;
        town: string | null;
        district: string | null;
    };
    listingName: string;
    country: Country;
}

export const useGTMEcommercePropertyListTracking = (params: IPropertiesTrackingParams) => {
    const containerId = useId();

    useElementWasVisible({
        ids: [containerId],
        onElementVisible: () => {
            if (params.country === Country.SPAIN) {
                hitEcommercePropertiesListView({
                    offer: params.offer,
                    properties: params.properties,
                    region: params.region,
                    listingName: params.listingName || "na"
                });
            }
        },
        once: true,
        threshold: 0.05
    });

    return containerId;
};
